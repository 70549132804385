import { LosseLink, useLosseLayout, useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Component_Header } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import Logo from '~/components/elements/Logo'
import { type PageBodyData } from '~/templates/page'
import HeaderVacancy from './HeaderVacancy'

export interface HeaderLoaderData extends Omit<PageBodyData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const { isSticky } = useLosseLayout()
  const data = useLoaderData<HeaderLoaderData>()
  const headerTheme = useHeaderTheme()

  return (
    <>
      <nav data-component="Header" className={clsx(isSticky && '', 'absolute left-0 top-0 right-0 z-40 header--height transition-all')}>
        <div className="container flex h-full items-center justify-between">
          <LosseLink to="/" aria-label="Home" className="hover:opacity-50 transition-opacity">
            {/* 0,210650887573964 */}
            <Logo
              className={clsx(
                headerTheme === 'light' && 'text-black',
                headerTheme === 'dark' && 'text-white',
                'h-[32px] w-[150px] xl:h-[37px] xl:w-[177px] -mt-2'
              )}
            />
          </LosseLink>

          <HeaderDesktop />
          <HeaderMobile />
        </div>
      </nav>

      {['vacature', 'interneVacature'].includes(data?.page?.contentType?.node?.name) && <HeaderVacancy />}
    </>
  )
}

export function useHeaderTheme() {
  const { middleware } = useLoaderData<PageBodyData>()

  return middleware?.headerTheme
}
