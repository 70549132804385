import { LosseLink, LossePlaatjie, useLoaderData, type LoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import CopyButton from '~/components/elements/CopyButton'
import Form from '~/components/elements/Form'
import type { Expert, Page_Flexcontent_Flex_Form, Vacature } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'

export default function FormPerfectMatch({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const data = useLoaderData<LoaderData<Vacature>>()

  const url = data.request.url.replace(':80', '')
  const getRedirectUrl = (name: string) => url + `${url.endsWith('/') ? '' : '/'}bedankt?Naam=${name}`
  const isInternalVacancy = url.includes('interne-vacatures/')

  const relation = fields?.relation as Expert

  const [contentRef, contentVisible] = useIsVisible()
  const [formRef, formVisible] = useIsVisible()

  return (
    <section data-component="FormPerfectMatch" className="section bg-site-base" id={fields?.sectionId || 'form-perfect-match'}>
      <div className="container lg:pb-24 py-12 lg:pt-32">
        <div className="sm:grid flex flex-col-reverse grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 lg:gap-32">
          <div ref={contentRef}>
            <h2
              className={clsx(
                contentVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'text-white title--medium !leading-[1.2] spacing--small--bottom sm:block hidden'
              )}
            >
              {fields.title}
            </h2>
            <h3
              className={clsx(
                contentVisible && 'animate-fade-up animate-ease-in-out animate-delay-75 animate-duration-500',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'text-site-accent title--normal !leading-[1.2] sm:block hidden'
              )}
            >
              {fields.subtitle}
            </h3>
            <div
              className={clsx(
                contentVisible && 'animate-fade-up animate-ease-in-out animate-delay-150 animate-duration-500',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'spacing--normal--top lg:spacing--big--top flex gap-5 lg:gap-8 lg:pl-14'
              )}
            >
              <LossePlaatjie maxwidth={200} className="w-[120px] lg:w-[180px]" src={relation.featuredImage.node} />
              <div>
                <div className="flex flex-col gap-[2px] py-4 sm:py-6">
                  <span className="text-white text-xl lg:text-2xl font-bold">{relation.title}</span>
                  <span className="text-site-accent">{relation.recap.function}</span>
                </div>
                <div className="mt-2">
                  <div className="grid grid-cols-2 gap-3 sm:gap-4 w-[300px]">
                    {relation.recap.phone && (
                      <CopyButton
                        className="btn--outline--white"
                        value={<>{`Bel ${relation.recap.firstname}`}</>}
                        hiddenValue={relation.recap.phone}
                        mobileHiddenValue={`tel:${relation.recap.phone}`}
                        classNameHiddenValue="text-sm"
                      />
                    )}
                    {relation.recap.email && (
                      <CopyButton
                        className="btn--outline--white"
                        value={<>{`Mail ${relation.recap.firstname}`}</>}
                        hiddenValue={relation.recap.email}
                        mobileHiddenValue={`mailto:${relation.recap.email}`}
                        classNameHiddenValue="text-sm"
                      />
                    )}
                    {relation.recap.whatsapp && (
                      <LosseLink to={relation.recap.whatsapp.url} className="btn--accent col-span-2">
                        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="WhatsApp">
                            <path
                              id="Vector"
                              d="M0.944336 22.5051L2.50942 16.9661C1.50392 15.2625 0.979312 13.3229 0.979312 11.331C0.979312 5.19785 5.97185 0.209229 12.1098 0.209229C18.2477 0.209229 23.2403 5.19785 23.2403 11.331C23.2403 17.4641 18.2477 22.4527 12.1098 22.4527C10.195 22.4527 8.32385 21.9635 6.66258 21.0374L0.944336 22.5051ZM6.95986 19.0105L7.30086 19.2202C8.74354 20.1026 10.4048 20.5656 12.1098 20.5656C17.2072 20.5656 21.3517 16.4244 21.3517 11.331C21.3517 6.23751 17.2072 2.09634 12.1098 2.09634C7.01232 2.09634 2.8679 6.23751 2.8679 11.331C2.8679 13.1045 3.37503 14.8256 4.32807 16.3109L4.5554 16.6691L3.65482 19.8579L6.95986 19.0105Z"
                              fill="white"
                            />
                            <path
                              id="Vector_2"
                              d="M8.84222 5.56702L8.0922 5.51863C7.85725 5.50895 7.63133 5.58638 7.4506 5.7509C7.08915 6.08964 6.51082 6.73808 6.33009 7.58976C6.06803 8.8576 6.47467 10.4061 7.53194 11.9546C8.5892 13.5031 10.5682 15.9807 14.0653 17.0357C15.1948 17.3744 16.0804 17.1421 16.7581 16.6776C17.3003 16.3098 17.6708 15.7097 17.8064 15.042L17.9238 14.4419C17.96 14.2483 17.8696 14.0645 17.707 13.9774L15.1948 12.7385C15.0322 12.6611 14.8334 12.7095 14.7249 12.8644L13.7309 14.2387C13.6586 14.3451 13.5321 14.3838 13.4147 14.3354C12.7369 14.0838 10.4597 13.0579 9.21271 10.4835C9.15849 10.3674 9.16753 10.2319 9.24886 10.1448L10.1977 8.97374C10.2971 8.8576 10.3152 8.68339 10.2609 8.53822L9.17657 5.80897C9.12235 5.67348 8.9868 5.5767 8.84222 5.56702Z"
                              fill="white"
                            />
                          </g>
                        </svg>

                        {`Whatsapp ${relation.recap.firstname}`}
                      </LosseLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref={formRef} className="md:flex justify-center">
            <h2
              className={clsx(
                contentVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'text-white title--medium !leading-[1.2] spacing--small--bottom sm:hidden block'
              )}
            >
              {fields.title}
            </h2>
            <h3
              className={clsx(
                contentVisible && 'animate-fade-up animate-ease-in-out animate-delay-75 animate-duration-500',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'text-site-accent title--normal !leading-[1.2] sm:hidden block mb-8'
              )}
            >
              {fields.subtitle}
            </h3>

            <div
              className={clsx(
                formVisible && 'animate-fade-up animate-ease-in-out animate-delay-300 animate-duration-500',
                formVisible ? 'opacity-100' : 'opacity-0',
                'md:max-w-[420px]'
              )}
            >
              <Form
                theme="dark--alternative"
                className="form--perfect-match"
                data={fields.form}
                onSubmitDone={async (values) => {
                  if (!isInternalVacancy) return

                  window.location.href = getRedirectUrl(values.input_1_3)
                }}
                scrollToConfirm={!isInternalVacancy}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
