import { losseContentParse } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useSessionStorage } from 'usehooks-ts'
import Content from '~/components/elements/Content'
import { type ElasticCandidate } from '~/services/elastic.server'

interface CandidateProps extends React.HTMLAttributes<HTMLDivElement> {
  source: ElasticCandidate
  className?: string
}

export default function Candidate({ source, className, ...rest }: CandidateProps) {
  const [selectedCandidates, setSelectedCandidates] = useSessionStorage('selected-candidates', [])
  const isSelected = selectedCandidates.includes(source.id)
  const [region, salary, shift, travel] = source?._content

  return (
    <div
      className={clsx('block--shadow rounded-md p-4 sm:p-7 border-4', isSelected ? 'border-site-conversion' : 'border-white', className)}
      {...rest}
    >
      <div className="sm:pl-6 sm:flex items-start justify-between gap-2">
        <h3 className="title--normal">{source.title}</h3>
        <span className="label--black--small hover:!cursor-text max-sm:mt-2 inline-block sm:block min-w-[90px] text-center">
          CV Nr. {source.id}
        </span>
      </div>
      <div className="flex flex-wrap mt-3 md:mt-4 gap-3 sm:pl-6">
        {salary && <span className="label--outline--black">{losseContentParse(salary)}</span>}
        {shift && <span className="label--outline--black">{losseContentParse(shift)}</span>}
        {travel && <span className="label--outline--black">{losseContentParse(travel)}</span>}
      </div>
      <div className="md:flex mt-4 md:mt-6 gap-6 xl:gap-10">
        <Content
          className={clsx(
            'flex-grow bg-site-grey-light rounded-md p-6',
            'list--stripe children-ul:text-sm',
            'list--stripe children-li:before:bg-site-accent max-lg:children-li:text-xs max-lg:children-ul:gap-2'
          )}
        >
          {source.usps.replace(/\\n/g, '')}
        </Content>
        <div className="bg-site-grey-light flex md:flex-col max-md:items-center justify-between rounded-md p-4 max-md:mt-4">
          <div className="md:text-right">
            <div className="text-lg font-bold group-hover:text-white text-site-base">{losseContentParse(region)}</div>
          </div>
          <button
            type="button"
            onClick={() => {
              if (isSelected) {
                setSelectedCandidates(selectedCandidates.filter((candidate) => candidate !== source.id))
              } else {
                setSelectedCandidates([...selectedCandidates, source.id])
              }
            }}
            className={clsx('btn--conversion md:mt-4 h-[36px] w-[160px]', isSelected && 'opacity-50')}
          >
            {!isSelected && (
              <span className="flex gap-2">
                <span>+</span>Toevoegen
              </span>
            )}
            {isSelected && (
              <span className="flex gap-2">
                <span className="rotate-45 block">+</span>Verwijderen
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
