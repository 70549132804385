/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, type PropsWithChildren } from 'react'
import { LosseLayout, useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header/Header'
import PopupHelp from './popups/PopupHelp'
import PopupVacancyAlert from './popups/PopupVacancyAlert'
import ReviewsHandle from './ReviewsHandle'
import PopupCandidatesAlert from './popups/PopupCandidatesAlert'
import useIsLighthouse from '~/hooks/useIsLighthouse'
import clsx from 'clsx'
import { useUtm } from '~/hooks/utm'

export default function Layout({ children }: PropsWithChildren<{}>) {
  return (
    <LosseLayout>
      <Content>{children}</Content>
    </LosseLayout>
  )
}

function Content({ children }: React.PropsWithChildren<{}>) {
  const { isScrollable, setScrollable } = useLosseLayout()
  const location = useLocation()
  const isLighthouse = useIsLighthouse()

  useEffect(() => {
    if (isScrollable) document.body.style.overflow = 'auto'
    else document.body.style.overflow = 'hidden'
  }, [isScrollable])

  useEffect(() => {
    if (!isScrollable) setScrollable(true)
  }, [location.pathname])

  useUtm()

  return (
    <>
      <Header />
      <main className={clsx('min-h-screen-dynamic header--padding--top', isLighthouse && 'disable-animations')}>{children}</main>
      <PopupHelp />
      <PopupVacancyAlert />
      <PopupCandidatesAlert />
      <ReviewsHandle />
      <Footer />
      <script src="https://static.scoreapp.com/js/integration/v1/embedding.js?v=0px7Nh"></script>
    </>
  )
}
