import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Expert as ExpertType, Page, Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import Expert from './post-types/Expert'
import { LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import { Fragment, useRef, useState } from 'react'
import { useInView } from 'framer-motion'
import useIsFirstComponent from '~/hooks/useIsFirstComponent'
import { shuffleArray } from '~/utils/general'

export default function ExpertsOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const temp = [...fields.posts.edges]
  const isFirstComponent = useIsFirstComponent(fields)
  const { page } = useLoaderData<{ page: Page }>()

  const [experts] = useState(() => shuffleArray(temp.slice(0, 17)))

  const titleRef = useRef(null)
  const expertsRef = useRef(null)
  const ctaRef = useRef(null)

  const titleIsVisible = useInView(titleRef, { once: true })
  const expertsIsVisible = useInView(expertsRef, { once: true })
  const ctaIsVisible = useInView(ctaRef, { once: true })

  return (
    <section
      data-component="ExpertsOverview"
      className={clsx(isFirstComponent ? 'md:section--pt' : 'section--pt', 'max-w-[1900px] mx-auto relative overflow-hidden')}
    >
      <div className="container">
        {fields.addbreadcrumbs && (
          <div
            className={clsx(
              titleIsVisible && 'animate-fade-up animate-delay-200 animate-ease-in-out animate-duration-500',
              titleIsVisible ? 'opacity-100' : 'opacity-0',
              'pb-3 sm:pb-8 block sm:hidden'
            )}
          >
            <Breadcrumbs />
          </div>
        )}
        <div ref={titleRef} className="grid grid-cols-1 gap-8 md:grid-cols-2">
          <div>
            <h1
              className={clsx(
                titleIsVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                titleIsVisible ? 'opacity-100' : 'opacity-0',
                'title--large'
              )}
            >
              {fields.title.split('|').map((t, i) => (
                <Fragment key={i}>
                  <span className={clsx(i === 1 && 'pl-8 sm:pl-12 lg:pl-24')}>{t}</span>
                  <br />
                </Fragment>
              ))}
            </h1>
          </div>
          <div className="flex items-center">
            <Content
              className={clsx(
                titleIsVisible && 'animate-fade-up animate-delay-150 animate-ease-in-out animate-duration-500',
                titleIsVisible ? 'opacity-100' : 'opacity-0',
                'children-p:text-base sm:children-p:text-lg children-p:font-bold sm:children-p:!leading-8'
              )}
            >
              {fields.description}
            </Content>
          </div>
        </div>
        {fields.addbreadcrumbs && (
          <div
            className={clsx(
              titleIsVisible && 'animate-fade-up animate-delay-200 animate-ease-in-out animate-duration-500',
              titleIsVisible ? 'opacity-100' : 'opacity-0',
              'pt-3 sm:pt-8 sm:block hidden'
            )}
          >
            <Breadcrumbs />
          </div>
        )}
      </div>

      <div
        ref={expertsRef}
        className={clsx(
          expertsIsVisible && 'animate-fade-up animate-delay-500 animate-ease-in-out animate-duration-500',
          expertsIsVisible ? 'opacity-100' : 'opacity-0',
          'grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 pt-8 lg:pt-16 xl:pt-28'
        )}
      >
        {experts.map((edge, index) => {
          const expert = edge?.node as ExpertType

          return (
            <Fragment key={index}>
              {/* Standard green blocks */}
              {/* {(index === 3 || index === 18) && <div className="bg-site-accent h-full" />} */}

              {/* Big CTA block */}
              {index === 9 && <BigCtaBlock description={fields.cta.description} className="max-lg:hidden" />}

              {index === 11 && (
                <div
                  ref={ctaRef}
                  className={clsx('bg-site-accent px-6 xl:px-8 pt-6 xl:pt-9 pb-6 flex flex-col justify-between max-lg:hidden')}
                >
                  <div
                    className={clsx(
                      ctaIsVisible && 'animate-fade-up animate-delay-1000 animate-ease-in-out animate-duration-500',
                      ctaIsVisible ? 'opacity-100' : 'opacity-0',
                      'text-white text-xl xl:text-3xl font-bold'
                    )}
                  >
                    {fields.cta.title}
                  </div>
                  <LosseLink
                    target={fields.cta.link.target}
                    to={fields.cta.link.url}
                    className={clsx(
                      ctaIsVisible && 'animate-fade-up animate-delay-[1500ms] animate-ease-in-out animate-duration-500',
                      ctaIsVisible ? 'opacity-100' : 'opacity-0',
                      'font-bold hover:underline pr-5 inline-flex items-center'
                    )}
                  >
                    {fields.cta.link.title}
                    <svg className="mb-1" width="16" height="16" viewBox="0 0 12 12" fill="none">
                      <g clipPath="url(#clip0_1052_5879)">
                        <path
                          d="M2.99955 11.2501C2.99957 11.1515 3.01901 11.054 3.05676 10.9629C3.09451 10.8719 3.14983 10.7892 3.21955 10.7196L7.05505 6.88407C7.17115 6.76799 7.26326 6.63018 7.3261 6.4785C7.38893 6.32682 7.42128 6.16425 7.42128 6.00007C7.42128 5.83589 7.38893 5.67332 7.3261 5.52164C7.26326 5.36996 7.17115 5.23215 7.05505 5.11607L3.22405 1.28507C3.08743 1.14362 3.01184 0.954168 3.01355 0.757521C3.01525 0.560873 3.09413 0.372764 3.23319 0.233708C3.37224 0.0946517 3.56035 0.015775 3.757 0.0140662C3.95365 0.0123574 4.1431 0.0879532 4.28455 0.224572L8.11554 4.05557C8.63063 4.57162 8.91992 5.27095 8.91992 6.00007C8.91992 6.72919 8.63063 7.42853 8.11554 7.94457L4.27955 11.7806C4.17463 11.8854 4.04101 11.9567 3.89556 11.9856C3.75012 12.0144 3.59938 11.9995 3.4624 11.9428C3.32541 11.886 3.20833 11.7899 3.12595 11.6666C3.04357 11.5433 2.99958 11.3984 2.99955 11.2501Z"
                          fill="#1D1D1B"
                        />
                      </g>
                    </svg>
                  </LosseLink>
                </div>
              )}

              <div className={clsx(index <= 3 ? '' : `${clsx(page.databaseId === 41 && 'max-sm:hidden')}`)}>
                <Expert key={expert.databaseId} expert={expert} />
              </div>
            </Fragment>
          )
        })}
      </div>
    </section>
  )
}

function BigCtaBlock({ description, className }: { description: string; className?: string }) {
  const ctaDescriptionRef = useRef(null)
  const ctaDescriptionIsVisible = useInView(ctaDescriptionRef, { once: true })

  return (
    <div ref={ctaDescriptionRef} className={clsx('bg-site-base p-4 xl:py-11 xl:px-12 col-span-1', className && className)}>
      <Content
        className={clsx(
          ctaDescriptionIsVisible && 'animate-fade-up animate-delay-1000 animate-ease-in-out animate-duration-500',
          ctaDescriptionIsVisible ? 'opacity-100' : 'opacity-0',
          'text-base xl:text-lg 2xl:text-xl 2xl:leading-8 font-bold'
        )}
        theme="dark"
      >
        {description}
      </Content>
    </div>
  )
}
